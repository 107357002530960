<template>
    <div class="main-box">
        <div class="pd-box">
            <a-form :model="formPostDemand" :label-col="labelCol" :wrapper-col="wrapperCol">
                <div class="content">
                    <div class="con-item" v-for="(formItem, formi) in formLists" :key="formi">
                        <a-form-item :label="formItem.PROJECT_NAME" >
                            <a-input-number v-model:value="formItem.AMOUNT" :disabled="!isEdit ? true : false" style="width: 100%;margin-left: 50px;" placeholder="请输入数据"></a-input-number>
                        </a-form-item>
                    </div>
                </div>
            </a-form>
        </div>
        <div class="con-box" style="text-align: center;">
            <a-button type="primary" @click="onSave">保存当前</a-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters([
            'orgId',
            'loginType',
            'isAuth',
            'isEdit',
            'y'
        ])
    },
    watch: {
        y: function (newVal) {
            this.getCSCSList(newVal)
        }
    },
    data () {
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            formLists: []
        }
    },
    methods: {
        // 获取利润表
        getCSCSList (y) {
            this.$store.dispatch('getCSCSList', { showCount: 20, currentPage: 1, ENTERPRISE_ID: this.orgId, YEAR: y, tm: new Date().getTime() }).then(res => {
                this.formLists = []
                if (res.result === 'success') {
                    this.$store.commit('SET_XXSTATUS', false)
                    this.formLists = res.varList
                } else {
                    this.$message.error('系统异常，获取列表失败，请稍后重试！')
                }
            })
        },
        onSave () {
            if (!this.isEdit) {
                this.$message.info('请先点击编辑后才能进行保存！')
                return false
            }
            let DATA_IDS = '', DATA_VALUES = ''
            for (let i in this.formLists) {
                DATA_IDS += this.formLists[i].ENTERSHOUDONGTC_ID + ','
                if (this.formLists[i].AMOUNT !== '' && this.formLists[i].AMOUNT) {
                    DATA_VALUES += this.formLists[i].AMOUNT + ','
                } else {
                    DATA_VALUES += '0,'
                }
            }
            DATA_IDS = DATA_IDS.substring(0, DATA_IDS.length - 1)
            DATA_VALUES = DATA_VALUES.substring(0, DATA_VALUES.length - 1)
            this.$store.dispatch('saveCSCS', {
                DATA_IDS: DATA_IDS,
                DATA_VALUES: DATA_VALUES,
                tm: new Date().getTime(0)
            }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('保存成功！')
                    this.$store.commit('SET_ISEDIT', false)
                } else {
                    this.$message.error('系统异常，保存失败，请稍后重试！')
                }
            })
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        this.getCSCSList(this.y)
    }
}
</script>
<style lang="less" scoped>
.ant-form ::v-deep(label){
    font-size: 12px;
}
::v-deep(.ant-form-item){
    margin-bottom: 10px !important;
}
::v-deep(.ant-form-item-label){
    overflow: visible;
}
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
    }

    .pd-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .content{
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            border-bottom: 1px solid #bbbbbb;
            .con-item{
                width: 50%;
            }
        }
    }
}
</style>
